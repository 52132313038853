import React from "react";
import './RMC.css';
import ServicePage from "../../servicePage/servicePage";
const RMC = ()=>{
    const content = {
        class: "Service2",
        title: "Requirements Management Consultation",
        content: "At Actuality, we understand the critical importance of clear and well-defined requirements for any project. Our requirements management consultation services help you articulate your project's needs and goals. We work closely with you to document, prioritize, and validate requirements, ensuring that your project is built on a solid foundation."
    }
    return(
        <ServicePage content ={content} />
    )
}
export default RMC;