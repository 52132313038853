import React, { useState, useEffect } from 'react';
import "./home.css";
import { useNavigate } from 'react-router-dom';




const words = ['BRINGING', 'IDEAS', 'FROM THE', 'REALM OF', 'THOUGHT', 'INTO', 'REALITY',""];

export const HomePage = ({setisActive,isActive}) => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const [stop,setStop] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {
      const interval = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % words.length);
      }, 285);
      if(activeIndex===7){
        setStop(true)
      }
      
      return () => clearInterval(interval);
    }, [activeIndex]);

    return (
      <div className="home">
        {words.map((word, index) => (
          <div
            className={`word-wrapper ${activeIndex === index ? `active` : ``} ${
              activeIndex !== 0 && index === 0 ? 'passed' : ''
            } `}
            key={index}
          >
            <h1
              className={`appear${index + 1} ${activeIndex === index && !stop ? `active` : ``} ${
                index === 0 || index === 2 || index === 5 ? `hollowed` : ``
              } ${(activeIndex !== index && activeIndex > index) || stop ? 'passed' : ''} `}
            >
              {word}
            </h1>
          </div>
        ))}
        <button style={{ backgroundColor: "transparent" ,cursor: "pointer" }}  onClick={()=>{navigate('./contact_us'); setisActive(4)}}> Contact Us </button>
      </div>
    );
  };
  



