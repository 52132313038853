// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyBjBaYQTAH4xcn0VrxsAPVce5I85MoNAhg",
  authDomain: "test-d6b86.firebaseapp.com",
  databaseURL: "https://test-d6b86-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "test-d6b86",
  storageBucket: "test-d6b86.appspot.com",
  messagingSenderId: "449045303418",
  appId: "1:449045303418:web:29dbb7edc61eb2d2bf2bc3",
  measurementId: "G-EZ0YFHRJ06"
};


const app = initializeApp(firebaseConfig);
export const Auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);