import React from "react";
import './AI.css';

import ServicePage from "../../servicePage/servicePage";

const AI =()=>{
    const content = {
        class: "Service4",
        title: "AI Automation",
        content: "In an increasingly digital world, AI automation is a game-changer. Our AI experts work to integrate automation into your processes, enhancing efficiency and reducing manual labor. We develop intelligent systems that can learn and adapt, streamlining tasks, and providing valuable insights to drive your business forward."
    }
    return(
        <ServicePage content = {content} />
    )
}
export default AI;