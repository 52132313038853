import React from "react";
import './WebDev.css';

import ServicePage from "../../servicePage/servicePage";

const WebDev =()=>{
    const content = {
        class: "Service6",
        title: "Web Development",
        content: "Our web development services focus on creating responsive, user-friendly websites that not only look great but also perform exceptionally. We build websites tailored to your needs, whether it's a business website, e-commerce platform, or a web application. Our websites are designed to attract and engage your target audience."
    }
    return(
        <ServicePage content={content} />
    )
} 
export default WebDev;