import {React, useState} from "react";
import './SideScroller.css';

const SideScroller =({content})=>{
    const [index,setIndex]= useState(0);
    
    const [clicked,setClicked] = useState(0);
    const [isShown,setIsShown] = useState(false);
    return(
        <div className="SideScroller">
             <div className="buttons"  onMouseEnter={()=>setIsShown(true)} onMouseLeave={()=>setIsShown(false)}>
                {/* {console.log(content.length-1)} */}
                <button className={`left ${isShown&&index!==0?"Show":""}`} key={"leftButton"} onClick={()=>{setClicked(1);setIndex(index>0?index -1:index);}}>{"<"}</button>
                <div className={`content ${isShown?"ShowContent":""}`}  key="content" >
                    {index===0?
                    <div />:
                        <div className={`${content[index-1].class} ${clicked===2&&index!==content.length-1? "rightCLicked":"" } ${clicked===1&&index!==0? "leftCLicked":"" }` } >
                            <h2>{content[index-1].title}</h2>
                            <p>{content[index-1].content}</p>
                        </div>
                    }
                    <div className={`${content[index].class} ${clicked===2&&index!==content.length-1? "rightCLicked":"" } ${clicked===1&&index!==0? "leftCLicked":"" }`}>
                        <h2>{content[index].title}</h2>
                        <p>{content[index].content}</p>
                    </div>
                    {index===content.length-1?<div/>:
                        <div className={`${content[index+1].class} ${clicked===2&&index!==content.length-1? "rightCLicked":"" } ${clicked===1&&index!==0? "leftCLicked":"" }` } >
                            <h2>{content[index+1].title}</h2>
                            <p>{content[index+1].content}</p>
                        </div>
                    }
                </div>
                <button className={`right ${isShown&&(index!==content.length-1) ?"Show":""}`}  key="rightButton"  onClick={()=>{setClicked(2);setIndex(index<(content.length-1)?index+1:index);}} >{">"}</button>
            </div>
        </div>
    )
}
export default SideScroller;