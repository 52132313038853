import React from "react";
import './ESD.css';
import ServicePage from "../../servicePage/servicePage";

const ESD = ()=> {
    const content = {
        class: "Service3",
        title: "Embedded Systems Development",
        content: "We excel in developing embedded systems that power innovative solutions. Our team of skilled programmers specializes in designing and implementing embedded systems for various applications. Whether it's for IoT devices, consumer electronics, or industrial automation, we can create custom solutions to meet your specific needs."
    }
    return(
        <ServicePage content = {content} />
    )
}
export default ESD;