import {React} from "react";
import './About_us.css';
 
import SideScroller from "../../SideScroller/SideScroller";
const AboutUs = ()=>{
    
    const content = [
        {   
            class :"MissionAndVision",
            title:"Mission and Vision", 
            content:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Et in impedit eligendi! Dolorum illo praesentium culpa voluptates optio, unde architecto error nisi impedit, qui id est, explicabo obcaecati magni rem."
        },
        
    ];
    
    /* useEffect(() => {
        const interval = setInterval(() => {
            setTimeout(3000);
        },  3500 );
        
        
        return () => clearInterval(interval);
      }, []); */
    return(
        <div className="AboutUs">
            <SideScroller content= {content}/>
        </div>
    )
}
export default AboutUs;