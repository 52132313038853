import React, { useState } from "react";
import './Contact_us.css';

import { db } from "../../fireBase/firebase-utility";
import { doc, setDoc } from "firebase/firestore"; 
const ContactUs= ()=>{
    
    const [messageData, setMessageData] = useState(false);
    const [name,setName] =useState();
    const [email,setEmail] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [message,setMessage] = useState("");
    const [currentTime,setCurrentTime] =useState()
    const collectionRef = doc(db,"quearies",`${Date().toLocaleString()}`);
    const onSubmitQuery = async (event)=>{
        try{
            console.log("ran");
            event.preventDefault(); 
            setCurrentTime(Date().toLocaleString());
            await setDoc(collectionRef,{
                Name: name,
                Email: email,
                Phone: phoneNumber,
                message: message,
                
            })
            setMessageData(true);
            setName("");
            setEmail('');
            setPhoneNumber('');
            setMessage("");
            setMessageData("");
        }catch(err){
            console.error(err);
        }
    }
    return(
        <div className="ContactUs">
            <form className="queries" onSubmit={onSubmitQuery}>
                {messageData?(
                    <label>Submit Successful</label>
                ):("")}
                <input type="text" placeholder ="Name" value={messageData?"":name} required onChange={(e)=>{setName(e.target.value)}}/>
                <input type="text" placeholder="Email" value ={messageData?"":email}  required onChange={(e)=>{setEmail(e.target.value)}}/>
                <input type="text" placeholder="Phone no."value ={messageData?"":phoneNumber} onChange={(e)=>{setPhoneNumber(e.target.value)}} />
                <textarea style={{resize: "none"}} required value ={message} className="inputPara" id="" cols="100" rows="10" placeholder="Your message"  onChange={(e)=>{setMessage(e.target.value)}}/>
                <input type="submit" className="button" style={{ backgroundColor: "transparent" ,cursor: "pointer" }} />
            </form>
            <div className="header">
                <h3>For additional quearies contact us with:</h3>
                <h3>Email: Someemail@Email.com</h3>
                <h3>Number: +92 9328190831</h3>
            </div>
        </div>
    )
}
export default ContactUs;
