import React from "react";
import './servicePage.css';

const ServicePage = ({content})=>{
    return(
        <div className="servicePage">
            <div className="content">
                <h2>{content.title}</h2>
                <p>{content.content}</p>
            </div>
        </div>
    )
}
export default ServicePage;