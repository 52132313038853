import React from "react";
import './Blog.css';

const  Blog = () =>{
    return (
        <div>
           
        </div>
    )
}
export default Blog;