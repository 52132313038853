import {React} from 'react';
import { useNavigate } from 'react-router-dom';
import './Services.css';

import SideScroller from '../../SideScroller/SideScroller';
const Services = ()=> {
    const content = [
        [{   
            class: "Service1",
            route:'/services/project-management-consultation',
            title:"Project Management Consultation:", 
            content: "Our project management consultation services are designed to guide you through the complex journey of turning your ideas into reality. We assist you in planning, organizing, and executing projects efficiently. Our team of aspiring project managers offers valuable insights and strategies to ensure that your projects are completed successfully, on time, and within budget."
        }
        ,{
            class: "Service2",
            route: '/services/requirements-management-consultation ',
            title: "Requirements Management Consultation",
            content: "At Actuality, we understand the critical importance of clear and well-defined requirements for any project. Our requirements management consultation services help you articulate your project's needs and goals. We work closely with you to document, prioritize, and validate requirements, ensuring that your project is built on a solid foundation."
        }],
        [{
            class: "Service3",
            route: '/services/embedded-systems-development',
            title: "Embedded Systems Development",
            content: "We excel in developing embedded systems that power innovative solutions. Our team of skilled programmers specializes in designing and implementing embedded systems for various applications. Whether it's for IoT devices, consumer electronics, or industrial automation, we can create custom solutions to meet your specific needs."
        }
        ,{
            class: "Service4",
            route: '/services/ai-automation',
            title: "AI Automation",
            content: "In an increasingly digital world, AI automation is a game-changer. Our AI experts work to integrate automation into your processes, enhancing efficiency and reducing manual labor. We develop intelligent systems that can learn and adapt, streamlining tasks, and providing valuable insights to drive your business forward."
        }]
        ,[{
            class: "Service5",
            title: "System Integration",
            route: '/services/system-integration',
            content: "System integration is essential for a seamless and harmonious operation of your various technologies and processes. Our team specializes in integrating diverse systems to work together cohesively. We ensure that your systems communicate efficiently and share data, optimizing your operations and reducing redundancy."
        }
        ,{
            class: "Service6",
            title: "Web Development",
            route: '/services/web-development',
            content: "Our web development services focus on creating responsive, user-friendly websites that not only look great but also perform exceptionally. We build websites tailored to your needs, whether it's a business website, e-commerce platform, or a web application. Our websites are designed to attract and engage your target audience."
        }]
        ,[{
            class: "Service7",
            title: "Graphic Design",
            route: '/services/graphic-design',
            content: "Visual appeal is a powerful tool in marketing and branding. Our graphic design experts craft captivating visuals that represent your brand effectively. From logos to marketing materials, we create designs that leave a lasting impression and help your brand stand out."
        }]
    ]
    const navigate = useNavigate();
    const pairedServices = [];
   

    return(
        <div className='Services'>
            
            <div className='Header'>
                <h2>Overview</h2>
                <p>At Actuality, we are passionate about bringing ideas from the realm of thought into reality. As a dynamic team of aspiring project managers and skilled programmers, we offer a range of services to transform your vision into tangible solutions. Our expertise includes project management consultation, ensuring your ventures are well-planned and executed with precision</p>
            </div>
            <table>
                {console.log(pairedServices
                    )}
            {content.map((c, i) => (
                <tr key={c[0].class}>
                    <th onClick={()=>{navigate(c[0].route)}}>{c[0].title}</th>
                    {c[1] && <th onClick={()=>{navigate(c[1].route)}}>{c[1].title}</th>}
                </tr>
            ))}
            </table>
           
        </div>
)
}
export default Services;