import React from "react";
import './faq.jsx';

const FAQ = () =>{
    return(
        <div>
            
        </div>
    )
}

export default FAQ;