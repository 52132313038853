import React from "react";
import './project-man.css';
import ServicePage from "../../servicePage/servicePage";
const ProjectMan = ()=>{
    const content = {   
        class: "Service1",
        title:"Project Management Consultation:", 
        content: "Our project management consultation services are designed to guide you through the complex journey of turning your ideas into reality. We assist you in planning, organizing, and executing projects efficiently. Our team of aspiring project managers offers valuable insights and strategies to ensure that your projects are completed successfully, on time, and within budget."
    }
    return(
        <ServicePage content = {content}  />
    )
}
export default ProjectMan;