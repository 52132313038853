import React from "react";
import './header.css';
import   Logo from '../../Assits/logo 1.png';
import { useNavigate } from "react-router-dom";
export const Header = ({setisActive,isActive,isNotClicked,setisNotClicked})=>{
    /* const [homeClicked, sethomeClicked] = useState(false); */
    const navigate = useNavigate();
    const handleHomeClick = event => {
       setisActive(0);
       navigate("/");
    }
    const handleAboutClick = event =>{
        setisActive(1);
        navigate("/about-us")
    }
    const handleServicesClick = event => {
        setisActive(2);
       navigate('/services')
    }
    /* const handleBlogClick = event => {
        setisActive(3);
        navigate('/blog')
    } */
    const handleContactClicked =event =>{
        setisActive(4);
        navigate("/contact_us")
    }
   /*  const handleFaqClicked = event =>{
        setisActive(5);
        navigate("/faq")
    } */
    return(
        <div className="header">
            <h2 onClick={handleHomeClick}  className={ `${ isActive===0 &&!isNotClicked ? "clicked" : "" }`}>HOME</h2>
            <h2 onClick={handleAboutClick} className={ `${ isActive===1&& !isNotClicked  ? "clicked" : "" }`}>ABOUT US</h2>
            <h2 onClick={handleServicesClick} className= { `${isActive===2 && !isNotClicked? "clicked" : ""}`}>SERVICES</h2>
            {/* <h2 onClick={handleBlogClick} className={ `${isActive===3 ? "clicked" : ""}`}>BLOG</h2> */}
            <h2 onClick={handleContactClicked} className={`${isActive===4 && !isNotClicked? "clicked" : ""}`}>CONTACT US</h2>
            {/* <h2 onClick={handleFaqClicked} className={`${isActive===5 ? "clicked" : ""}`}>FAQ</h2> */}
            <img src= {Logo} alt= "Logo" className="logo"/>
            
        </div>
    )
}