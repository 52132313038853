
import './App.css';
import {React, useState , useEffect} from 'react';
import { Route,Routes,useLocation } from 'react-router-dom';
import {ReactComponent as Net} from "./Assits/Group 3.svg"
import { Header } from './Components/header/header';

import { HomePage } from './Components/Pages/Home/homePage';
import AboutUs from './Components/Pages/About_us/About_us';
import Portfolio from './Components/Pages/Portfolio/Portfolio';
import Services from './Components/Pages/Services/Services';
import Blog from './Components/Pages/Blog/Blog';
import Careers from './Components/Pages/Careers/Careers';
import ContactUs from './Components/Pages/Contact_us/Contact_us';
import FAQ from './Components/Pages/faq/faq';

import ProjectMan from './Components/Pages/project-man/project-man';
import RMC from './Components/Pages/RMC/RMC';
import ESD from './Components/Pages/ESD/ESD';
import AI from './Components/Pages/AI/AI';
import SystemIntegration from './Components/Pages/SystemIntegration/SystemIntegration';
import WebDev from './Components/Pages/WebDev/WebDev';
import GraphicDesign from './Components/Pages/GraphicDesign/GraphicDesign';

function App() {
  const location = useLocation();
  const [isNotClicked,setisNotClicked]= useState(false);
  const [isActive, setisActive] = useState(0);
  const pages = [
    { route: '/', component: HomePage },
    { route: '/about-us', component: AboutUs },
    { route: '/portfolio', component: Portfolio },
    { route: '/services', component: Services },
    { route: '/blog', component: Blog },
    { route: '/careers', component: Careers },
    { route: '/contact_us', component: ContactUs },
    { route: '/faq', component: FAQ },
    {route: '/services/project-management-consultation' ,component: ProjectMan},
    {route: '/services/requirements-management-consultation ', component:RMC },
    {route: '/services/embedded-systems-development',component:ESD},
    {route: '/services/ai-automation', component: AI},
    {route: '/services/system-integration', component: SystemIntegration},
    {route: '/services/web-development', component:WebDev},
    {route: '/services/graphic-design', component:GraphicDesign}
  ];

  useEffect(()=>{
    if (location.pathname === '/' && isActive !== 0) {
        setisActive(0);
        setisNotClicked(true);
    }else if (location.pathname === '/about-us' && isActive !== 1) {
          setisActive(1);
          setisNotClicked(true);
    }else if (location.pathname === '/services' && isActive !== 2) {
          setisActive(2);
          setisNotClicked(true);
    
    }else if (location.pathname === '/contact_us' && isActive !== 4) {
          setisActive(4);
          setisNotClicked(true);
    }
    setisNotClicked(true);  
  },[setisNotClicked,isActive,location])
 
  
  
  return (
    <div className="App">
      <header>
          <Header setisActive = {setisActive} isActive={isActive} isNotClicked={isNotClicked} setisNotClicked={setisNotClicked} />
      </header>
        <Routes>
          {pages.map((page,i)=>(
            <Route exact path={page.route} element={<page.component setisActive={setisActive} isActive={isActive}/>} key= {`page${i}`}/>
          ))}
         {/*  <Route exact path= '/' element={<HomePage setisActive = {setisActive} isActive={isActive} />} />
          <Route exact path= '/about-us' element={<AboutUs/>}/>
          <Route exact path= '/portfolio' element={<Portfolio/>} />
          <Route exact path= '/services' element={ <Services/> } />
          <Route exact path ='/blog' element= {<Blog/>}/>
          <Route exact path ='/careers' element= {<Careers/>}/>
          <Route exact path ='/contact_us' element= {<ContactUs/>}/>
          <Route exact path ='/faq' element= {<FAQ/>}/> */}
        </Routes>
        <Net className={`state${isActive}`}/>
        
      
    </div>
  );
}

export default App;
