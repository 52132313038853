import React from "react";
import './GraphicDesign.css';

import ServicePage from "../../servicePage/servicePage";;

const GraphicDesign = ()=>{
    const content = {
        class: "Service7",
        title: "Graphic Design",
        content: "Visual appeal is a powerful tool in marketing and branding. Our graphic design experts craft captivating visuals that represent your brand effectively. From logos to marketing materials, we create designs that leave a lasting impression and help your brand stand out."
    };
    return(
        <ServicePage content = {content}/>
    )
}
export default GraphicDesign;