import React from "react"; 
import './Careers.css';


const Careers = () => {
    return(
        <div>
            
        </div>
    )
} 
export default Careers;