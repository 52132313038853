import React from "react";
import './SystemIntegration.css'

import ServicePage from "../../servicePage/servicePage";
const SystemIntegration= ()=>{
    const content = {
        class: "Service5",
        title: "System Integration",
        content: "System integration is essential for a seamless and harmonious operation of your various technologies and processes. Our team specializes in integrating diverse systems to work together cohesively. We ensure that your systems communicate efficiently and share data, optimizing your operations and reducing redundancy."
    }
    return(
        <ServicePage content ={content}/>
    )
}
export default SystemIntegration;